
export default function Giftcards(props) {
    return (
        <>
            <div className="p-5 sm:p-10 mx-5 sm:mx-14 xl:w-2/5 bg-blue-700 text-white rounded-3xl my-10">
                <div className="justify-center text-left">
                <h2 className="text-3xl font-bold text-left  my-5">
                    {props.title}
                </h2>
                <h3 className="text-2xl font-thin">
                    Hallo {props.owner}
                </h3>
                <p className="my-5">
                    {props.gift_text}
                </p>
                    <p className="text-xl font-thin">Viele Grüße, {props.sender}</p>
                    <div className="grid grid-cols-2 my-5 gap-3">
                        <p>Is still valid:</p>
                        <p>{props.unused !== true?"No":"Yes"}</p>
                        <p>Date of expiration:</p>
                        <p>{props.expiration_date}</p>
                    </div>
                </div>
            </div>
        </>
    )

}
