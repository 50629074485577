import React from "react";
import { supabase } from "./supabaseClient";
import { useState } from "react";
import Giftcards from "./components/Giftcards";


export default function App() {
    const [error, setError] = useState(false);
    const [gotGiftcard, setGotGiftcard] = useState(false);
    const [searched, setSearched] = useState(false);
    const [publicKey, setPublicKey] = useState("");
    const [giftcard, setGiftcard] = useState({});

    function handlePublicKeyChange(event) {
        setPublicKey(event.target.value);
    }

    async function getGiftcard() {
        const { data, error } = await supabase
            .from("giftcards")
            .select("*")
            .eq("pb_key", publicKey)
            .single();

        setSearched(true)

        if (error) {
            setError(true);
        } else {
            setGiftcard(data);
            setGotGiftcard(true);
            setError(false)
        }

    }

    return (
        <>
            <div className="flex flex-col items-center justify-center min-h-screen py-2 text-center">
                <div className="flex flex-col items-center justify-center m-10">
                    <h1 className="text-3xl md:text-6xl font-extrabold p-10 bg-blue-700 text-white rounded-2xl px-20 m-5"> giftcard-manager</h1>
                    <p className="m-10 sm:w-1/2 text-xl md:text-3xl">
                        You've received a gift card from me?
                        Please enter the code I gave you to find out more about your gift. If you have questions on how to use this site head
                        over to the <a href="https://github.com/MauritzOrlinski">Github-repo (Currently not public)</a>.
                    </p>
                    <input className="bg-gray-500 text-white rounded-2xl p-2 border-4 border-blue-700" type="text" placeholder="Enter your public-key here" value={publicKey} onChange={handlePublicKeyChange} />
                    <button className="bg-blue-700 text-white rounded-2xl p-2 border-4 border-blue-700 m-5 px-6 hover:scale-95 " onClick={getGiftcard}>Search</button>
                </div>
                {searched?(!error && gotGiftcard?giftcard && <Giftcards title={giftcard.title} gift_text={giftcard.gift_text} owner={giftcard.owner} sender={giftcard.sender} unused={giftcard.unused} expiration_date={giftcard.expiration_date} />:<p>None found</p>):<></>}
            </div>
            <footer className="md:text-3xl mt-auto">
                <div className="bg-black">
                    <div className="flex flex-row items-center justify-between py-4 ">
                        <p className="text-gray-400 ">© 2023 Mauritz Orlinski</p>
                        <div className="flex text-gray-200">
                            <a className="mr-4 hover:scale-110" href="https://github.com/MauritzOrlinski">GitHub</a>
                            <a className="mr-4 hover:scale-110" href="https://mauritzorlinski.com">mauritzorlinski.com</a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}